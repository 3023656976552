import React, { Component } from "react"
import { format, isFuture } from "date-fns"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTransition from "gatsby-plugin-page-transitions"

export const query = graphql`
  {
    upcoming: allSanityLive(sort: { fields: [date], order: [ASC] }) {
      edges {
        node {
          venue
          url
          date
        }
      }
    }
    past: allSanityLive(sort: { fields: [date], order: [DESC] }) {
      edges {
        node {
          venue
          url
          date
        }
      }
    }
  }
`

export default class Live extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReversed: false,
    }
  }

  reverseArray() {
    this.setState({
      isReversed: !this.state.isReversed,
    })
  }
  render() {
    const { data } = this.props
    const mapUpcoming = data.upcoming.edges
      .filter(({ node: live }) => isFuture(live.date))
      .map(({ node: live }) => {
        return (
          <div id="live__item" className="tr" key={live.date}>
            <div className="td date">
              {format(new Date(live.date), "DD/MM/YYYY")}
            </div>
            <div className="td">
              <h4 className="subtitle">{live.venue}</h4>
            </div>
            {live.url !== null && (
              <div className="tr">
                <div className="td" />
                <div className="td">
                  <a href={live.url} target="_blank" rel="noopener noreferrer">
                    info
                  </a>
                </div>
              </div>
            )}
          </div>
        )
      })
    const mapPast = data.past.edges
      .filter(({ node: live }) => !isFuture(live.date))
      .map(({ node: live }) => (
        <div id="live__item" className="tr" key={live.date}>
          <div className="td date">
            {format(new Date(live.date), "DD/MM/YYYY")}
          </div>
          <div className="td">
            <h4 className="subtitle">{live.venue}</h4>
          </div>
          {live.url !== null && (
            <div className="tr">
              <div className="td" />
              <div className="td">
                <a href={live.url} target="_blank" rel="noopener noreferrer">
                  info
                </a>
              </div>
            </div>
          )}
        </div>
      ))
    return (
      <PageTransition
        defaultStyle={{
          transition: `opacity 250ms ease-in-out`,
          opacity: 0,
        }}
        transitionStyles={{
          entering: { opacity: 0 },
          entered: { opacity: 1 },
        }}
        transitionTime={100}
      >
        <Layout location={this.props.location}>
          <SEO title="Live" />
          <div id="live">
            <div id="live__box">
              <div>
                <h1 className="left upper">UPCOMING</h1>
              </div>

              {mapUpcoming}

              <div>
                <h1 className="left lower">PAST</h1>
              </div>
              {mapPast}
            </div>
          </div>
        </Layout>
      </PageTransition>
    )
  }
}
